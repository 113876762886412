import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
Vue.use(ElementUI);

import TEditor from '@/components/TEditor.vue'
Vue.component('TinymceEditor',TEditor)

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
