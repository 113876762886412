
<template>
  <div id="app">
    <router-view v-if="routerAlive"  />
  </div>
</template>
 
<script>
export default {
  data(){
    return{
      routerAlive:true
    }
  },
  provide(){
    return {
      routerRefresh: this.routerRefresh
    }
  },
  methods:{
    routerRefresh(){
      this.routerAlive = false;
      this.$nextTick(()=>{
          this.routerAlive = true;
      });
    },
  }
 
}
</script>

<style>
.el-button{
		box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.1);
	}
	.el-button--text{
		box-shadow: 0 0 0;
	}
	.el-button--primary{
		box-shadow: 0 5px 7px 0 rgba(86,119,252,.2);
	}
	.el-button--success{
		box-shadow: 0 5px 7px 0 rgba(7,193,96,.2);
	}
	.el-button--danger{
		box-shadow: 0 5px 7px 0 rgba(235,9,9,.2);
	}
	.el-table{
		margin-top: 10px;
		
	}
	.el-table__header tr,
	  .el-table__header th {
	    padding: 0;
	    height: 40px;
	    font-size: 12px;
	    text-align: center;
	}
	.el-table__body tr,
	  .el-table__body td {
	    padding: 0;
	    height: 40px;
	    font-size: 12px;
	    text-align: center;
	}
</style>
