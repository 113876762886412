import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  
  {
    path: '/',
    redirect: '/index/index'
  },
  {
    path: '/Login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/article/:aid',
    component:  () => import(/* webpackChunkName: "article" */ '../views/Article.vue')
  },
  {
    path: '/index',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
    
    children: [
      {
        path: '/index/index',
        component:  () => import(/* webpackChunkName: "index" */ '../components/right/index.vue')
      },
      {
        path: '/index/add',
        component:  () => import(/* webpackChunkName: "index" */ '../components/right/add.vue')
      },
      {
        path: '/index/edit/:id',
        component:  () => import(/* webpackChunkName: "index" */ '../components/right/edit.vue')
      },
      {
        path: '/index/articleList/:mid',
        component:  () => import(/* webpackChunkName: "index" */ '../components/right/articleList.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//路由守卫，拦截未登录页面
router.beforeEach((to, from, next) => {
  //用你的方式获取登录的用户信息
  const islogin = localStorage.islogin
  if(islogin=='islogin' || to.name === 'Login'){
      //如果存在用户信息，或者进入的页面是登录页面，则直接进入
      next()
  }else {
      //不存在用户信息则说明用户未登录，跳转到登录页面，带上当前的页面地址，登录完成后做回跳，
      //如未登录用户进入用户中心的页面地址，检测到未登录，
      //自动跳转到登录页面，并且带上用户中心的页面地址，登录完成后重新跳到个人中心页面。
      next({
        name: 'Login',
        query: {
          redirect: to.path
        }
      })
  }
})

export default router
